







import { Component, Prop, Vue } from "vue-property-decorator";

import DataRequestForm from "@/settings/components/networks-management/DataRequestForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";

@Component({
  components: {
    DataRequestForm,
  },
})
export default class DataRequestView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  destroyed() {
    this.$store.commit("clearRequestParams");
  }
}
