













































































































































































































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";

import EntityCard from "./EntityCard.vue";
import RequestParamsTable from "./RequestParamsTable.vue";
import RequestSpecialParamsTable from "./RequestSpecialParamsTable.vue";
import NetworkUrlRequestModel, {
  HttpMethod,
  PaginationType,
  RequestType,
} from "@/settings/models/networks-management/NetworkUrlRequestModel";
import NetworkUrlResponseModel from "@/settings/models/networks-management/NetworkUrlResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    EntityCard,
    RequestParamsTable,
    RequestSpecialParamsTable,
  },
})
export default class DataRequestForm extends Vue {
  @Prop() activeTab!: string;
  @Prop() networkIntegrationId!: number;

  @Ref("urlForm") readonly urlForm!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly expirationDaysRules = [
    ValidUtil.requiredNumber(this.$lang("validation.required")),
    ValidUtil.integer(this.$lang("validation.integer")),
    ValidUtil.minNumber(1, this.$lang("validation.min", 1)),
  ];
  readonly httpMethodItems = Object.entries(HttpMethod).map(([key, value]) => ({
    text: key,
    value,
  }));
  readonly paginationTypeItems = Object.entries(PaginationType).map(
    ([key, value]) => ({
      text: this.$lang(`appAdmin.networksManagement.${key.toLowerCase()}`),
      value,
    })
  );
  readonly authRequestTypes = [
    RequestType.AUTH,
    RequestType.PERSISTENT_AUTH,
  ].map((value) => ({
    text: this.$lang(`appAdmin.networksManagement.${value.toLowerCase()}`),
    value,
  }));

  localValue: NetworkUrlRequestModel = new NetworkUrlRequestModel();
  currentNetworkRequest: NetworkUrlResponseModel | null = null;
  isUrlFormValid = true;
  isEditableNetworkRequest = false;

  get networkRequestsIsLoading(): boolean {
    return this.$store.state.networksManagementStore.networkRequestsIsLoading;
  }

  get savingNetworkRequestInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingNetworkRequestInProgress;
  }

  get networkRequests(): Array<NetworkUrlResponseModel> {
    return this.$store.state.networksManagementStore.networkRequests;
  }

  get isAuthenticationTab(): boolean {
    return this.activeTab === "authentication";
  }

  get isEndDateVisible(): boolean {
    return (
      !!this.localValue.pagination?.type &&
      [
        PaginationType.DATE_INTERVAL,
        PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
        PaginationType.DATE_INTERVAL_WITH_OFFSET,
        PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
      ].includes(this.localValue.pagination.type)
    );
  }

  get isNextKeyVisible(): boolean {
    return (
      !!this.localValue.pagination?.type &&
      [
        PaginationType.DATE_INTERVAL_WITH_NEXT_URL,
        PaginationType.DATE_INTERVAL_WITH_NEXT_TOKEN,
      ].includes(this.localValue.pagination.type)
    );
  }

  get isMinContentLengthVisible(): boolean {
    return (
      !!this.localValue.pagination?.type &&
      [
        PaginationType.OFFSET,
        PaginationType.DATE_INTERVAL_WITH_OFFSET,
      ].includes(this.localValue.pagination.type)
    );
  }

  get isLengthPageVisible(): boolean {
    return (
      this.localValue.pagination?.type ===
      PaginationType.DATE_INTERVAL_WITH_OFFSET
    );
  }

  get isPersistentAuth(): boolean {
    return this.localValue.requestType === RequestType.PERSISTENT_AUTH;
  }

  @Watch("isPersistentAuth")
  watchIsPersistentAuth(value: boolean) {
    if (value && !this.localValue?.expirationDays) {
      this.localValue.expirationDays = 30;
    }
  }

  @Watch("networkRequests", { immediate: true, deep: true })
  private watchNetworkRequests() {
    if (this.currentNetworkRequest) {
      this.resetLocalData();
    }

    if (this.networkRequests.length) {
      const currentRequestTypes = this.isAuthenticationTab
        ? [RequestType.AUTH, RequestType.PERSISTENT_AUTH]
        : [RequestType.DATA_FETCH];
      const found = this.networkRequests.find(({ requestType }) =>
        currentRequestTypes.includes(requestType)
      );

      if (found) {
        this.currentNetworkRequest = NetworkUrlResponseModel.ofSource(found);
        this.localValue = NetworkUrlRequestModel.ofSource(found);
      }
    }
  }

  @Watch("currentNetworkRequest", { immediate: true, deep: true })
  private watchCurrentNetworkRequest(value: NetworkUrlResponseModel | null) {
    if (value) {
      this.$store.dispatch("loadRequestParamsByRequestId", {
        id: value.id,
      });
      this.$store.dispatch("loadRequestSpecialParamsByRequestId", value.id);
    }
  }

  @Watch("activeTab", { immediate: true })
  private watchActiveTab() {
    if (!this.currentNetworkRequest) {
      this.localValue.requestType = this.isAuthenticationTab
        ? RequestType.AUTH
        : RequestType.DATA_FETCH;
    }
  }

  @Watch("networkIntegrationId", { immediate: true })
  private watchNetworkIntegrationId(value: number) {
    this.localValue.networkIntegrationId = value;
  }

  handleCreateNetworkRequest() {
    if (!this.urlForm.validate()) {
      return;
    }

    this.$store.dispatch("createNetworkRequest", this.localValue);
  }

  async handleUpdateNetworkRequest() {
    if (!this.urlForm.validate()) {
      return;
    }

    await this.$store.dispatch("updateNetworkRequest", {
      payload: this.localValue,
      id: this.currentNetworkRequest?.id,
    });

    this.isEditableNetworkRequest = false;
  }

  resetLocalData() {
    this.currentNetworkRequest = null;
    this.localValue = new NetworkUrlRequestModel();
    this.urlForm?.resetValidation();
  }

  cancelEdit() {
    this.localValue = this.currentNetworkRequest
      ? NetworkUrlRequestModel.ofSource(this.currentNetworkRequest)
      : new NetworkUrlRequestModel();
    this.isEditableNetworkRequest = false;
  }
}
